var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":_vm.title,"visible":_vm.visible,"width":"850px","before-close":_vm.closeDialog,"close-on-click-modal":false,"append-to-body":"","element-loading-background":_vm.loadingBackground,"custom-class":"add-concat-dialog"},on:{"update:visible":function($event){_vm.visible=$event},"open":_vm.openDialog}},[_c('el-form',{ref:"addForm",attrs:{"label-width":"135px","model":_vm.addForm,"rules":_vm.addType != 4 ? _vm.addFormRules : {},"inline":true}},[(_vm.addType != 4)?_c('el-form-item',{attrs:{"label":"服务商名称:","prop":"serviceProvider"}},[_c('el-input',{attrs:{"placeholder":"服务商名称","title":"服务商名称","disabled":_vm.addType == 3},model:{value:(_vm.addForm.serviceProvider),callback:function ($$v) {_vm.$set(_vm.addForm, "serviceProvider", $$v)},expression:"addForm.serviceProvider"}})],1):_vm._e(),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"serviceProviderType","label":"服务商类型:"}},[_c('e6-vr-select',{attrs:{"data":_vm.serviceProviderTypeList,"placeholder":"服务商类型","title":"服务商类型","clearable":"","virtual":"","is-title":true,"props":{
          id: 'codeValue',
          label: 'codeName'
        },"disabled":_vm.addType == 3},model:{value:(_vm.addForm.serviceProviderType),callback:function ($$v) {_vm.$set(_vm.addForm, "serviceProviderType", $$v)},expression:"addForm.serviceProviderType"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"服务商状态:","prop":"serviceProviderStatus"}},[_c('e6-vr-select',{attrs:{"data":_vm.serviceProviderStatusList,"placeholder":"服务商状态","title":"服务商状态","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        },"disabled":_vm.addType == 3},model:{value:(_vm.addForm.serviceProviderStatus),callback:function ($$v) {_vm.$set(_vm.addForm, "serviceProviderStatus", $$v)},expression:"addForm.serviceProviderStatus"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"isExamine","label":"是否考核:"}},[_c('e6-vr-select',{attrs:{"data":_vm.isExamineList,"placeholder":"是否考核","title":"是否考核","clearable":"","virtual":"","is-title":true,"props":{
          id: 'codeValue',
          label: 'codeName'
        },"disabled":_vm.addType == 3},model:{value:(_vm.addForm.isExamine),callback:function ($$v) {_vm.$set(_vm.addForm, "isExamine", $$v)},expression:"addForm.isExamine"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"税率(%):","prop":"taxRate"}},[_c('el-input',{attrs:{"placeholder":"税率(%)","title":"税率(%)","precision":2,"disabled":_vm.addType == 3},on:{"input":function($event){return _vm.limitInput($event, 'taxRate')}},model:{value:(_vm.addForm.taxRate),callback:function ($$v) {_vm.$set(_vm.addForm, "taxRate", $$v)},expression:"addForm.taxRate"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"开票公司编码:","prop":"billingCompanyNo"}},[_c('el-input',{attrs:{"placeholder":"开票公司编码","title":"开票公司编码","disabled":_vm.addType == 3},model:{value:(_vm.addForm.billingCompanyNo),callback:function ($$v) {_vm.$set(_vm.addForm, "billingCompanyNo", $$v)},expression:"addForm.billingCompanyNo"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"开票公司名称:","prop":"billingCompany"}},[_c('el-input',{attrs:{"placeholder":"开票公司名称","title":"开票公司名称","disabled":_vm.addType == 3},model:{value:(_vm.addForm.billingCompany),callback:function ($$v) {_vm.$set(_vm.addForm, "billingCompany", $$v)},expression:"addForm.billingCompany"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"发票类型:","prop":"invoiceType"}},[_c('e6-vr-select',{attrs:{"data":_vm.invoiceTypeList,"placeholder":"发票类型","title":"发票类型","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        },"disabled":_vm.addType == 3},model:{value:(_vm.addForm.invoiceType),callback:function ($$v) {_vm.$set(_vm.addForm, "invoiceType", $$v)},expression:"addForm.invoiceType"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"工程师承担税率(%):","prop":"engineerTaxRate"}},[_c('el-input',{attrs:{"placeholder":"工程师承担税率（%）:","title":"工程师承担税率（%）:","disabled":_vm.addType == 3},on:{"input":function($event){return _vm.limitInput($event, 'engineerTaxRate')}},model:{value:(_vm.addForm.engineerTaxRate),callback:function ($$v) {_vm.$set(_vm.addForm, "engineerTaxRate", $$v)},expression:"addForm.engineerTaxRate"}})],1),_vm._v(" "),(_vm.addType != 4)?_c('el-form-item',{attrs:{"label":"联系人:","prop":"contactUser"}},[_c('el-input',{attrs:{"placeholder":"联系人","title":"联系人","disabled":_vm.addType == 3},model:{value:(_vm.addForm.contactUser),callback:function ($$v) {_vm.$set(_vm.addForm, "contactUser", $$v)},expression:"addForm.contactUser"}})],1):_vm._e(),_vm._v(" "),(_vm.addType != 4)?_c('el-form-item',{attrs:{"label":"联系电话:","prop":"contactPhone"}},[_c('el-input',{attrs:{"placeholder":"联系电话","title":"联系电话","disabled":_vm.addType == 3},model:{value:(_vm.addForm.contactPhone),callback:function ($$v) {_vm.$set(_vm.addForm, "contactPhone", $$v)},expression:"addForm.contactPhone"}})],1):_vm._e(),_vm._v(" "),(_vm.addType != 4)?_c('el-form-item',{attrs:{"label":"省市区","prop":"defaultCity"}},[_c('e6-vr-select',{attrs:{"data":_vm.addressList,"placeholder":"省市区","title":"省市区","virtual":"","clearable":"","is-title":true,"remote":"","loading":_vm.selectLoading,"props":{
          id: 'fullName',
          label: 'fullName'
        },"disabled":_vm.addType == 3},on:{"filterChange":_vm.handleLoadAddressFilter,"change":_vm.handleCitySelect},model:{value:(_vm.addForm.defaultCity),callback:function ($$v) {_vm.$set(_vm.addForm, "defaultCity", $$v)},expression:"addForm.defaultCity"}})],1):_vm._e(),_vm._v(" "),(_vm.addType != 4)?_c('el-form-item',{attrs:{"label":"详细地址","prop":"address"}},[_c('el-input',{attrs:{"placeholder":"详细地址","maxlength":"255","disabled":_vm.addType == 3},model:{value:(_vm.addForm.address),callback:function ($$v) {_vm.$set(_vm.addForm, "address", $$v)},expression:"addForm.address"}})],1):_vm._e(),_vm._v(" "),(_vm.addType != 4)?_c('el-form-item',{attrs:{"label":"邮箱信息:","prop":"email"}},[_c('el-input',{attrs:{"placeholder":"邮箱信息","title":"邮箱信息","disabled":_vm.addType == 3},model:{value:(_vm.addForm.email),callback:function ($$v) {_vm.$set(_vm.addForm, "email", $$v)},expression:"addForm.email"}})],1):_vm._e()],1),_vm._v(" "),(_vm.addType != 3)?_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeDialog}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }