<!--
 * @Description: 新建服务商信息
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="title"
    :visible.sync="visible"
    width="850px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    v-loading="loading"
    @open="openDialog"
  >
    <el-form
      ref="addForm"
      label-width="135px"
      :model="addForm"
      :rules="addType != 4 ? addFormRules : {}"
      :inline="true"
    >
      <el-form-item
        label="服务商名称:"
        prop="serviceProvider"
        v-if="addType != 4"
      >
        <el-input
          v-model="addForm.serviceProvider"
          placeholder="服务商名称"
          title="服务商名称"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item prop="serviceProviderType" label="服务商类型:">
        <e6-vr-select
          v-model="addForm.serviceProviderType"
          :data="serviceProviderTypeList"
          placeholder="服务商类型"
          title="服务商类型"
          clearable
          virtual
          :is-title="true"
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          :disabled="addType == 3"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="服务商状态:" prop="serviceProviderStatus">
        <e6-vr-select
          v-model="addForm.serviceProviderStatus"
          :data="serviceProviderStatusList"
          placeholder="服务商状态"
          title="服务商状态"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          :disabled="addType == 3"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item prop="isExamine" label="是否考核:">
        <e6-vr-select
          v-model="addForm.isExamine"
          :data="isExamineList"
          placeholder="是否考核"
          title="是否考核"
          clearable
          virtual
          :is-title="true"
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          :disabled="addType == 3"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="税率(%):" prop="taxRate">
        <el-input
          v-model="addForm.taxRate"
          placeholder="税率(%)"
          title="税率(%)"
          :precision="2"
          :disabled="addType == 3"
          @input="limitInput($event, 'taxRate')"
        ></el-input>
      </el-form-item>
      <el-form-item label="开票公司编码:" prop="billingCompanyNo">
        <el-input
          v-model="addForm.billingCompanyNo"
          placeholder="开票公司编码"
          title="开票公司编码"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="开票公司名称:" prop="billingCompany">
        <el-input
          v-model="addForm.billingCompany"
          placeholder="开票公司名称"
          title="开票公司名称"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票类型:" prop="invoiceType">
        <e6-vr-select
          v-model="addForm.invoiceType"
          :data="invoiceTypeList"
          placeholder="发票类型"
          title="发票类型"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
          :disabled="addType == 3"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="工程师承担税率(%):" prop="engineerTaxRate">
        <el-input
          v-model="addForm.engineerTaxRate"
          placeholder="工程师承担税率（%）:"
          title="工程师承担税率（%）:"
          @input="limitInput($event, 'engineerTaxRate')"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系人:" prop="contactUser" v-if="addType != 4">
        <el-input
          v-model="addForm.contactUser"
          placeholder="联系人"
          title="联系人"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话:" prop="contactPhone" v-if="addType != 4">
        <el-input
          v-model="addForm.contactPhone"
          placeholder="联系电话"
          title="联系电话"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="省市区" prop="defaultCity" v-if="addType != 4">
        <e6-vr-select
          v-model="addForm.defaultCity"
          :data="addressList"
          placeholder="省市区"
          title="省市区"
          virtual
          clearable
          :is-title="true"
          remote
          @filterChange="handleLoadAddressFilter"
          @change="handleCitySelect"
          :loading="selectLoading"
          :props="{
            id: 'fullName',
            label: 'fullName'
          }"
          :disabled="addType == 3"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="详细地址" prop="address" v-if="addType != 4">
        <el-input
          v-model="addForm.address"
          placeholder="详细地址"
          maxlength="255"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱信息:" prop="email" v-if="addType != 4">
        <el-input
          v-model="addForm.email"
          placeholder="邮箱信息"
          title="邮箱信息"
          :disabled="addType == 3"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer" v-if="addType != 3">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  saveServiceProvider,
  findDownList,
  getAddressDownList,
  updateServiceProvider,
  batchUpdateServiceProvider
} from "@/api";
import { telAndMobileValid, emailsValid } from "@/utils/validate";
import { printError } from "@/utils/util";
export default {
  name: "addServiceProvider",
  components: {},
  data() {
    return {
      loading: false,
      invoiceTypeList: [],
      isExamineList: [],
      serviceProviderTypeList: [],
      selectLoading: false,
      addressList: [],
      serviceProviderStatusList: [],
      addForm: {
        serviceProvider: "",
        serviceProviderType: "",
        serviceProviderStatus: "",
        engineerTaxRate: "",
        defaultCity: "",
        isExamine: "",
        taxRate: "",
        billingCompanyNo: "",
        billingCompany: "",
        invoiceType: "",
        contactUser: "",
        contactPhone: "",
        province: "",
        city: "",
        country: "",
        address: "",
        email: ""
      },
      addFormRules: {
        serviceProvider: [
          {
            required: true,
            message: "请输入服务商名称",
            trigger: ["blur", "change"]
          }
        ],
        serviceProviderType: [
          {
            required: true,
            message: "请选择服务商类型",
            trigger: ["blur", "change"]
          }
        ],
        serviceProviderStatus: [
          {
            required: true,
            message: "请选择服务商状态",
            trigger: ["blur", "change"]
          }
        ],
        isExamine: [
          {
            required: true,
            message: "请选择是否考核",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: [
          {
            required: false,
            validator: telAndMobileValid.validator(),
            trigger: ["blur", "change"]
          }
        ],
        email: [
          {
            required: false,
            validator: emailsValid.validator(),
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    //1新增2编辑3详情
    addType: {
      type: [Number, String],
      default: 1
    },
    clickRow: {
      type: Object,
      default: () => {}
    },
    serviceIdList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.addVisible;
      },
      set(val) {
        this.$emit("update:addVisible", val);
      }
    },
    title() {
      let res;
      switch (this.addType) {
        case 1:
          res = "新建服务商信息";
          break;
        case 2:
          res = "编辑服务商信息";
          break;
        case 3:
          res = "服务商信息详情";
          break;
        case 4:
          res = "批量服务商信息";
          break;

        default:
          res = "";
          break;
      }

      return res;
    }
  },
  watch: {},
  created() {
    this.initData();
    this.loadAddressData();
  },
  methods: {
    async initData() {
      try {
        this.loading = true;
        let promiseList = [
          findDownList([
            "serviceProviderType",
            "invoiceType",
            "isExamine",
            "serviceProviderStatus"
          ])
        ];
        let [enumRes] = await Promise.all(promiseList);
        //服务商类型
        this.serviceProviderTypeList = this.getFreezeResponse(enumRes, {
          path: "data.serviceProviderType"
        });
        //发票类型
        this.invoiceTypeList = this.getFreezeResponse(enumRes, {
          path: "data.invoiceType"
        });
        //服务商状态
        this.serviceProviderStatusList = this.getFreezeResponse(enumRes, {
          path: "data.serviceProviderStatus"
        });
        //是否考核
        this.isExamineList = this.getFreezeResponse(enumRes, {
          path: "data.isExamine"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //打开
    openDialog() {
      if (this.addType != 1) {
        let clickRow = _.cloneDeep(this.clickRow);
        let province = clickRow.province ? clickRow.province : "";
        let city = clickRow.city ? clickRow.city : "";
        let country = clickRow.country ? clickRow.country : "";
        clickRow.defaultCity = province + city + country;
        this.loadAddressData(province + city + country);
        for (let key in clickRow) {
          clickRow[key] = clickRow[key] ? clickRow[key] : "";
          clickRow["serviceProviderType"] = clickRow["serviceProviderType"]
            ? clickRow["serviceProviderType"].toString()
            : "";
          clickRow["invoiceType"] = clickRow["invoiceType"]
            ? clickRow["invoiceType"].toString()
            : "";
          clickRow["isExamine"] = clickRow["isExamineName"]
            ? clickRow["isExamine"] + ""
            : "";
          clickRow["serviceProviderStatus"] = clickRow["serviceProviderStatus"]
            ? clickRow["serviceProviderStatus"].toString()
            : "";
        }
        this.addForm = { ...clickRow };
      }
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        // this.addForm.addressList = [];
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        this.selectLoading = true;
        let res = await getAddressDownList({
          addressName: val,
          level: 3,
          pageIndex: 1,
          pageSize: 300
        });
        //获取地址下拉框
        this.addressList = res.data.array;
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    limitInput(value, name) {
      this.addForm[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    },

    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.addForm.province = val.provinceName;
      this.addForm.city = val.cityName;
      this.addForm.country = val.countyName;
      this.addForm.defaultCity = val.fullName;
    },
    //点击确定
    confirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.addType == 1) {
            this.saveServiceProviderReq();
          } else if (this.addType == 2) {
            //编辑
            this.updateServiceProviderReq();
          } else if (this.addType == 4) {
            //批量编辑
            this.batchUpdateServiceProviderReq();
          }
        }
      });
    },
    //新增
    async saveServiceProviderReq() {
      try {
        this.loading = true;
        let res = await saveServiceProvider({
          ...this.addForm,
          contactPhone: this.addForm.contactPhone
            ? this.addForm.contactPhone
            : null,
          billingCompanyNo: this.addForm.billingCompanyNo
            ? this.addForm.billingCompanyNo
            : null,
          billingCompany: this.addForm.billingCompany
            ? this.addForm.billingCompany
            : null,
          contactUser: this.addForm.contactUser
            ? this.addForm.contactUser
            : null
        });
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.$emit("refreshList");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //编辑
    async updateServiceProviderReq() {
      try {
        this.loading = true;
        let res = await updateServiceProvider({
          ...this.addForm,
          contactPhone: this.addForm.contactPhone
            ? this.addForm.contactPhone
            : null,
          billingCompanyNo: this.addForm.billingCompanyNo
            ? this.addForm.billingCompanyNo
            : null,
          billingCompany: this.addForm.billingCompany
            ? this.addForm.billingCompany
            : null,
          contactUser: this.addForm.contactUser
            ? this.addForm.contactUser
            : null
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refreshList");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //批量编辑
    async batchUpdateServiceProviderReq() {
      try {
        this.loading = true;
        let res = await batchUpdateServiceProvider({
          serviceIdList: this.serviceIdList,
          ...this.addForm
        });
        if (res.code == "OK") {
          this.$message.success("批量修改成功");
          this.$emit("refreshList");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击取消
    closeDialog() {
      this.visible = false;
      this.addForm = {
        serviceProvider: "",
        serviceProviderStatus: "",
        serviceProviderType: "",
        defaultCity: "",
        isExamine: "",
        taxRate: "",
        billingCompanyNo: "",
        billingCompany: "",
        invoiceType: "",
        contactUser: "",
        engineerTaxRate: "",
        contactPhone: "",
        province: "",
        city: "",
        country: "",
        address: "",
        email: ""
      };
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 600px;
    .el-input {
      width: 250px;
    }
  }
}
</style>
